<script setup lang="ts">
interface Props {
  buttonValue?: string
  colorType?: 'success' | 'failure' | 'info' | 'warning' | 'neutral'
  textColor?: string
  bgColor?: string
  padding?: string
}

const { 
  buttonValue = 'Click', 
  colorType = 'neutral',
  textColor = 'secondary-60',
  bgColor = 'primary-50',
  padding = '2'
} = defineProps<Props>()

// Map color types to Tailwind classes for background colors
const colorMapping = {
  success: 'primary-50',
  failure: 'error-50',
  info: 'info-50',
  warning: 'warning-50',
  neutral: bgColor
}

// Computed property to set the appropriate Tailwind classes based on props
const buttonClasses = computed(() => {
  const bgColorClass = `bg-${colorMapping[colorType] || bgColor}`
  const textColorClass = `text-${textColor}`
  const paddingClass = `p-${padding}`
  return `${bgColorClass} ${textColorClass} ${paddingClass} rounded cursor-pointer my-4 transition ease-in-out duration-150 hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2`
})
</script>

<template>
  <button :class="buttonClasses" class="text-secondary-20 shadow" :value="buttonValue">
    {{ buttonValue }}
  </button>
</template>
